<template>
<!--博文列表页-->
<div>
  <div style="height:10px;"></div>
  <div style="display:flex;justify-content:center;padding-top:70px;">
    <div style="width:150px;background-color:rgb(50, 96, 148)">
      <div style="position:fixed;">
        <div v-for="(item,index) in type_list" :key="index">
          <div v-if="item.name==choice_type" class="left_btn" style="color:rgb(18, 172, 172);background-color:#FFFFFF;width:150px;transition: 0.3s;width:150px;">
            {{item.name}}
          </div>
          <div v-else  class="left_btn" style="color:#ffffff;" @click="choiceBtn(item.name)">
             {{item.name}}
          </div>
        </div>
      </div>
    </div>
    <!--列表详情-->
    <div style="background-color:#ffffff;width:800px;min-height:80vh;padding:30px;display:flex;flex-wrap:wrap;align-content:flex-start;">
        <div v-for="(item,index) in article_list" :key="index" class="article_card" @click="goArticleById(item._id)" >
          <div style="display:flex;">
            <div style="min-height:180px;">
              <img :src="item.img"  style="height:180px;width:180px;">
            </div>
            <div style="margin:15px;">
              <div style="height:115px;">
                <div style="font-size:17px;font-weight:800;" class="font_line">{{item.title}}</div>
                <div style="font-size:16px;margin-top:10px;-webkit-line-clamp:2" class="font_line">{{item.description}}</div>
              </div>
              <div style="font-size:13px;margin-bottom:10px;">{{item.create_time | timeFilter}}</div>
              <div style="display:flex;font-size:13px;align-items:center;">
                <img src="~@/assets/icon/yuedu.png" style="height:13px;width:13px;margin-top:2px;">
                <div style="margin-left:5px;margin-right:10px;">阅读 : {{item.look}}</div>
                <!-- <img src="~@/assets/icon/pinglun.png" style="height:13px;width:13px;">
                <div style="margin-left:5px;margin-right:10px;">静态</div> -->
                <img src="~@/assets/icon/zan.png" style="height:13px;width:13px;margin-top:2px;">
                <div style="margin-left:5px;">{{item.like}}</div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data (){
    return {
     type_list:[],//文章类型列表
     article_list:[{}],//文章列表
     choice_type:'',//选择的分类
    }
  },
  created(){
    this.getTypeList()
   
  },
  methods:{
    //获取文章分类列表
    getTypeList(){
      this.$axios.get(this.$url.getArticleType).then(res=>{
        this.type_list = res.data.data;
        this.choice_type = res.data.data[0].name
        this.getArticleByType()
      })
      
    },
    //根据类别id获取文章列表
    getArticleByType(){
      this.$axios.get(this.$url.getArticleByType + '?type=' + this.choice_type).then(res=>{
        this.article_list = res.data.data;
      })
    },
    //选择按钮
    choiceBtn(name){
      this.choice_type = name;
      this.getArticleByType();
    },
    //跳转到文章详情
    goArticleById(id){
      this.$router.push({
        path: './article',
        query: {id: id}
      })
    },
 

  }
}
</script>

<style scoped>
.left_btn{
  height:70px;
  width:140px;
  /* margin-left:10px; */
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  line-height:70px;
  cursor: pointer;
}
/*鼠标滑过放大box内图特效*/
.box{
  overflow:hidden;
}
.box~.box{
  margin-left: 15px;
}
.box img{
  width: 100%;
  height: 100%;
  transition: all 0.5s;
}
.box:hover img{
  transform: scale(1.3);
}

/*弹性盒子超出行数自动变...*/
.font_line{
    display: -webkit-box;
		/*将对象转为弹性盒模型展示*/
		-webkit-box-orient: vertical;
		/*设置弹性盒模型子元素的排列方式*/
		-webkit-line-clamp: 3;
		/*限制文本行数*/
		overflow: hidden;
		/*超出隐藏*/
}

.article_card{
  height:180px;
  width:390px;
  background-color :#F7F7F7;
  margin:5px;
  transform-style: preserve-3d;
  transition: 0.5s;
  cursor: pointer;
  border-radius:5px;
  overflow: hidden;
}
/* .article_card:hover{
   transform: rotateX(-10deg)rotateY(-15deg);
} */


</style>